.calcBody {
  align-items: center;
  background-color: #feb81c;
  border-radius: 10%;
  color: #000308;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 42rem;
}

.calcHeader {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.calculonIcon {
  margin-left: 2rem;
  width: 5rem;
}

.calcDisplay {
  align-items: center;
  background-color: #000308;
  border-radius: 0;
  color: #aaf787;
  display: flex;
  flex-direction: row;
  height: 4rem;
  justify-content: right;
  overflow: hidden;
  width: 75%;
}

.calcDisplay h1 {
  padding-right: 2.45rem;
}

.previousOperand {
  color: #73db43;
}

.calcButtons {
  display: flex;
  flex-direction: row;
}

.calcButtonWide {
  width: 230%;
}

#buttonGrid {
  display: inline-grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  margin-top: 2rem;
}

button {
  background-color: #000308;
  color: #aaf787;
  width: 5rem;
}

@media screen and (max-width: 800px) {
  /* Mid size media query */
  .calcBody {
    align-content: center;
    align-self: center;
    justify-content: center;
    max-width: fit-content;
    overflow: hidden;
    padding: 1rem;
    scale: 70%;
  }
  #buttonGrid {
    display: inline-grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    margin-top: 2rem;
  }
  button {
    background-color: #000308;
    color: #aaf787;
    margin: auto;
    width: 5rem;
  }
  .calcButtonWide {
    width: 220%;
  }
}

@media screen and (max-width: 550px) {
  /* Small media query */
  .calcBody {
    align-content: center;
    align-self: center;
    justify-content: center;
    margin: auto;
    max-width: fit-content;
    overflow: hidden;
    padding: 1rem;
    scale: 50%;
  }
  #buttonGrid {
    display: inline-grid;
    gap: 0.2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    margin-top: 2rem;
  }
  button {
    background-color: #000308;
    color: #aaf787;
    margin: auto;
    width: 3.5rem;
  }
  .calcButtonWide {
    width: 210%;
  }

  .calcHeader {
    display: none;
  }
  .calcDisplay {
    border-radius: 0;
    height: 3rem;
  }
  .calcDisplay h1 {
    font-size: xx-large;
    font-weight: 400;
    padding-right: 1rem;
  }
}
