#root {
  margin: 0 auto;
  max-width: 1280px;
  overflow: hidden;
  padding: 2rem;
  text-align: center;
}

:root {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  background-color: #242424;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  font-family: monospace, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-synthesis: none;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
  text-rendering: optimizeLegibility;
}

a {
  color: #646cff;
  font-weight: 500;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  display: flex;
  margin: 0;
  min-height: 100vh;
  min-width: 320px;
  place-items: center;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  background-color: #1a1a1a;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.6em 1.2em;
  transition: border-color 100ms;
}
button:hover {
  background-color: #303030;
  transition: ease-in-out, 0.3s;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}
